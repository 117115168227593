import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Row } from "antd";
import moment from "moment";
import { BaseUrlRace } from "../Api/BaseUrl";
import JsBarcode from "jsbarcode-fixed";

function PrintRO() {
  const [dataDetail, setDataDetail] = useState(null); // State for storing detail data
  const idMp = localStorage.getItem("idMp"); // Get idMp from localStorage
  const hariini = moment().format("YYYY-MM-DD"); // Get today's date

  useEffect(() => {
    document.title = `PrintRO_${hariini}`;

    // Fetch detail data and generate barcode
    const fetchData = async () => {
      if (idMp) {
        try {
          const response = await axios.get(
            `${BaseUrlRace}sp/get-ro-detail?id_mp=${idMp}`,
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );
          const detailData = response.data.data[0];
          setDataDetail(detailData); // Store detail data in state

          // Generate barcode with SP number
          if (detailData?.sp) {
            JsBarcode("#barcode", detailData.sp, {
              format: "CODE128",
              lineColor: "#000000",
              width: 1.9,
              height: 50,
              displayValue: true,
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [idMp]);

  if (!dataDetail) {
    return <p>No data available</p>; // Show a loading message if no data is available
  }

  return (
    <div className="print-one-body" style={{ backgroundColor: "white" }}>
      <div className="container print-one mb-5">
        <div>
          <div className="d-flex justify-content-center">
            <Row style={{ width: "100%" }} gutter={[16, 16]}>
              <Col
                md={4}
                className="d-flex justify-content-start align-items-center"
              >
                <img
                  src="https://elogs.eurekalogistics.co.id/assets/admin/dist/img/logo-race.png"
                  alt="Logo"
                  style={{ maxWidth: "50%", maxHeight: "50%" }} // Ensure the image fits well
                />
              </Col>
              <Col
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="text-center">
                  <h2 style={{ fontSize: 16 }}>
                    <b>
                      {dataDetail?.retur === 0
                        ? "PRINT RETUR ORDER"
                        : "PRINT RETUR ORDER"}
                    </b>
                  </h2>
                </div>
              </Col>
              <Col
                md={8}
                className="d-flex justify-content-end align-items-end"
              >
                <div className="text-center">
                  <svg id="barcode"></svg> {/* Barcode here */}
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <br />

        <div>
          <Row className="mt-3">
            <Col
              xs={24} // Full width on extra small screens
              sm={12} // 12 columns (50%) on small screens
              md={10} // 8 columns (33.33%) on medium screens
              style={{ fontSize: 13, fontWeight: "bold" }}
            >
              <table>
                <tbody>
                  <tr>
                    <td className="nowrap">Nomor Surat Jalan</td>
                    <td className="mx-2">:</td>
                    <td>{dataDetail.sp || "-"}</td>
                  </tr>
                  <tr>
                    <td className="nowrap">Tanggal</td>
                    <td className="mx-2">:</td>
                    <td>{dataDetail.tgl_pickup || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </Col>

            <Col
              xs={24} // Full width on extra small screens
              sm={12} // 12 columns (50%) on small screens
              md={12} // 8 columns (33.33%) on medium screens
              style={{ fontSize: 13, fontWeight: "bold" }}
            >
              <table>
                <tbody>
                  <tr>
                    <td>Nama Driver</td>
                    <td className="mx-2">:</td>
                    <td>{dataDetail.driver || "-"}</td>
                  </tr>
                  <tr>
                    <td>Plat Nomor</td>
                    <td className="mx-2">:</td>
                    <td>{dataDetail.noPol || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>

          <Row className="mt-1" style={{ fontSize: 13, fontWeight: "bold" }}>
            <Col
              xs={24} // Full width on extra small screens
              sm={24} // Full width on small screens
              md={16} // 16 columns (66.67%) on medium screens
            >
              <table>
                <tbody>
                  <tr>
                    <td className="nowrap">Alamat</td>
                    <td className="mx-2">:</td>
                    <td>{dataDetail.detail[0]?.alamatSekolah || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </div>

        <table
          style={{ fontSize: 12, border: "2px solid black" }}
          className="table table-bordered mt-3"
        >
          <thead className="text-center">
            <tr>
              <th rowSpan="2" className="align-middle">
                NO
              </th>
              <th rowSpan="2" className="align-middle">
                NO SJ PELANGGAN
              </th>
              <th rowSpan="2" className="align-middle">
                NAMA PELANGGAN
              </th>
              <th colSpan="3">JUMLAH</th>
            </tr>
            <tr>
              <th>FAKTUR</th>
              <th>COLLY</th>
              <th>IKAT</th>
            </tr>
          </thead>
          <tbody>
            {dataDetail.detail.map((item, index) => (
              <tr className="text-center" key={index}>
                <td>{index + 1}</td>
                <td>{item.sm}</td>
                <td>{item.sekolahTujuan}</td>
                <td>{item.qty}</td>
                <td>{item.koli}</td>
                <td>{item.ikat}</td>
              </tr>
            ))}
            <tr>
              <td colSpan="3" style={{ fontWeight: "bold", fontSize: "16px" }}>
                TOTAL
              </td>
              <td className="text-center">
                {dataDetail.detail.reduce((acc, item) => acc + item.qty, 0)}
              </td>
              <td className="text-center">
                {dataDetail.detail.reduce((acc, item) => acc + item.koli, 0)}
              </td>
              <td className="text-center">
                {dataDetail.detail.reduce((acc, item) => acc + item.ikat, 0)}
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ fontSize: 16 }}>
          <b>Note :</b>
        </div>
        <div
          style={{ border: "2px solid black", padding: 10, textAlign: "start" }}
        >
          <p>
            <b>
              <i style={{ fontSize: 16, fontWeight: "bold" }}>
                Buku yang diterima 100% menjadi tanggung jawab penerima dan
                tidak bisa diserahkan ke pihak manapun tanpa sepengetahuan
                pengirim. Jika ada yang pihak-pihak yang mengatasnamakan RAJA
                CEPAT dapat menghubungi Customer Service PT Raja Cepat Nusantara
                di nomor 089506185458.
              </i>
            </b>
          </p>
        </div>

        <div className="row text-center mt-3">
          <Row>
            <Col span={12} style={{ fontSize: 12, fontWeight: "bold" }}>
              <p>Pengirim</p>
              <p style={{ marginTop: 80 }}>
                (.............................................)
              </p>
              <p>
                <i>Nama, Tanda Tangan, dan Tanggal</i>
              </p>
              <p>
                <i>No HP:............................................</i>
              </p>
            </Col>
            <Col span={12} style={{ fontSize: 12, fontWeight: "bold" }}>
              <p>Penerima</p>
              <p style={{ marginTop: 80 }}>
                (.............................................)
              </p>
              <p>
                <i>Nama, Tanda Tangan, dan Tanggal</i>
              </p>
              <p>
                <i>No HP:............................................</i>
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default PrintRO;
